// import axios from "axios";
import { api } from '../../index';
import React, { useState } from "react";
import { useEffect } from "react";
import { Alert, Badge, Button, Card, Col, Form, Nav, Row, Tab, Table, Tabs ,Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BsDownload, BsFillEyeFill, BsFillFileEarmarkPdfFill } from "react-icons/bs";
import Header from "../../Components/Header";
import VerticalHeader from "../../Components/VerticalHeader";
import HorizontalHeader from "../../Components/HorizontalHeader";
import '../css/Dashboard.scss';
import AddPayment from "../Forms/AddPayment";
import PaymentReceipt from "../PaymentReceipt";
import PaymentView from "./PaymentView";
  
function Paiements() {
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [ListPayments, setListPayments] = useState([]);
  const [currentSort, setcurrentSort] = useState("default");
  const [payId, setPayID] = useState("");
  const [searchVal, setSearchVal] = useState('');
  const [PaymentRowSelected, setPaymentRowSelected] = useState([]);
  const [PayView, setPayView] = useState(false);
  const items = JSON.parse(localStorage.getItem('data'));
  const base_url = process.env.REACT_APP_URL;
  const [loading, setLoading] = useState(true);

  const showForm = () =>  {
     setShow(true); 
     setShowSuccess(false); 
  }
  const hideForm = () =>  {
    setShow(false);
    setPayView(false);
    api.get(`customer/${items.user.customer["CustomerAccount"]}/payments`).then(async (response) => {
      setListPayments(response.data.data)
    })
  }

  useEffect(() => {
    setLoading(true);

    api.get(`customer/${items.user.customer["CustomerAccount"]}/payments`).then(async (response) => {
          setListPayments(response.data.data)
    })    .finally(() => setLoading(false)); 

  },[]);
     

  const handleInputSearch = (e) => {
        setSearchVal(e.target.value);
  }
    let dataPaiementsIds = [];
         ListPayments.filter((index) => {
              dataPaiementsIds.push(index.PaymentId.toLowerCase(),index.period ? index.period.toLowerCase() :"" );
        });
    const searchIn= dataPaiementsIds.filter((index) => {
        return index.includes(searchVal.toLowerCase());
    });
    
    let dataPaiementsArray = [];
          ListPayments.forEach(index => {
            if (searchIn.includes(index.PaymentId.toLowerCase()) || searchIn.includes(index.period ? index.period.toLowerCase() :"")) {
               dataPaiementsArray.push(index)
            }
          })

          const SelectItem = (event) => { 
            setPayID(event.target.value)
          };

        const PaymentSelected = (event) =>{
          ListPayments.forEach(element => {
              if(element["_id"] == event.currentTarget.id){
                setPaymentRowSelected(element)
                setPayView(true)
              }
          });
        }

      const columns = [
        {
          name: '',
          selector: row => row.id,
          sortable: false,
      },
        {
            name: 'Numéro du paiement ',
            selector: row => row.num_payment,
            sortable: true,
        },
        {
            name: 'Date de paiement',
            selector: row => row.date_payment,
            sortable: true,
        },
        {
            name: 'Date de création',
            selector: row => row.created_date,
            sortable: true,
        },
        {
            name: 'Périodes',
            selector: row => row.periode,
            sortable: true,
        },
        {
          name: 'Montant du paiement ',
          selector: row => row.montant_payment,
          sortable: true,
        },
        {
          name: 'Pièce jointe',
          selector: row => row.piece_jointe,
          sortable: true,
        },
        {
          name: 'Statut',
          selector: row => row.status,
          sortable: true,
        },
        {
            name: "Action",
            selector: row => row.principale,
            sortable: true,
        }
      ];
    
    let data = [];
    dataPaiementsArray.map((index) => {
          data.push({
            id:<Form.Check
            inline
            value={index.PaymentId} 
            type="radio"
            onChange={SelectItem} 
            /> ,
            num_payment: index.PaymentId,
            date_payment : index.PaymDate,
            created_date: index.created_at,
            periode : index.period,
            montant_payment:index["PaymAmount"].toLocaleString(),
            piece_jointe: index.AttachementURL ?
            <Badge  className="p-2 badge-info " >
              <a  href={`${index.AttachementURL ? index.AttachementURL :"#"}`}>
                   Avis de credit 
              </a> 
              <BsDownload className="icon-size ml-2" />
            </Badge>
         :"-",
            status: index["Status"] === "Draft" ? "Brouillon" :
            index["Status"] === "Pending" ? "En attente de validation" :
            index["Status"] === "Accepted" ? "Accepté" :
            index["Status"] === "Declared" ? "Déclaré" :
            index["Status"] === "Declined" ? "Refusé" :"-" ,
            principale :<div className="container-radio m-0">
            <div className="btns-bar">
                <Button variant="outline-success" className="edit br-15" id={index["_id"]} onClick={PaymentSelected}><BsFillEyeFill /></Button>
            </div>
        </div>,
          });
        });
  return (
    <>
     <Header/>
        <Card className="mb-4 p-2 br-15 card-content">
            <VerticalHeader />

               <div className="parent m-2 w-100">
                      <div className="align-right back">
                          <Button variant="light"  onClick={hideForm}className="mt-4 uppercase back-btn small align-left">
                              <img src="./assets/back.svg" /> Retour
                          </Button>
                      </div>
                      <h1 className="title-form">Mes paiements </h1>
                      <span className="subtitle-form sm"> Effectuer un paiement, voir statut des paiements, consulter les paiements antérieurs.</span>
                            {PayView ?
                              <PaymentView PaymentRowSelected = {PaymentRowSelected}/>:
                              <div>
                                <div className={!show?"":"hide"}>
                                  <Form className="bar-top d-flex mt-4">
                                      <Form.Control
                                      type="search"
                                      placeholder="Rechercher"
                                      className="me-2 w-50 br-15 pl-1 p-1"
                                      aria-label="Rechercher"
                                      onChange={handleInputSearch}
                                      value={searchVal}
                                      />
                                      <div className="btns-bar dark">
                                          <Button className="btn-100 bg-primary-1 add-pay br-15 " id="AddPayment" onClick={showForm}>  <img src="./assets/add.svg"/> Ajouter un paiement </Button>
                                          <Button className="btn-100 paye br-30 " style={{ pointerEvents: !payId ? 'none' : 'auto'}} href={`${base_url}/export-payment-receipt/${payId}`}>  <BsFillFileEarmarkPdfFill className="icon-size"  /> Recu de paiement </Button>
                                      </div>
                                  </Form>
                                  <Row className="mt-2 scrol-x-t">
                                    {loading ? (
                    <div class="loader">
  <div class="loader-wheel"></div>
  <div class="loader-text"></div>
</div>
                    ) : (
                                      <DataTable
                                        columns={columns}
                                        data={data}
                                        pagination
                                        highlightOnHover
                                        fixedHeader
                                        fixedHeaderScrollHeight="500px"
                                      />
                                    )}
                                  </Row>
                                 
                                </div>
                                <Row  className={show?"mt-4":"hide"}>
                                    <AddPayment showSuccess = {showSuccess} setShowSuccess = {setShowSuccess}/>
                                </Row>
                              </div>
                            }
                            
                    </div>
                    <HorizontalHeader/>
               </Card>
    </>
  );
}
export default Paiements;