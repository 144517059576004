import React, { useEffect,useState } from 'react';
import './SabaPayment.css'; // Import the CSS file
// import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { FaLanguage, FaFlag } from 'react-icons/fa';

// Import JSON translations
import enTranslations from '../../locals/sabapayment/en.json';
import frTranslations from '../../locals/sabapayment/fr.json';
import { useParams } from 'react-router-dom';
import { api } from '../../index';


const PaymentPage = () => {
  const navigate = useNavigate();
  const { paymentid } = useParams();

  const [error, setError] = useState(null);

  const [amount, setAmount] = useState(null);
  const [orderNO, setOrderNo] = useState(null); 
  const baseurl = process.env.REACT_APP_API_URL;
  
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const [account_number, setAccountNumber] = useState('');


  const [language, setLanguage] = useState('en');
  const translations = language === 'en' ? enTranslations : frTranslations;
  useEffect(() => {
    const fetchPaymentData = async () => {
      await  api.get(`customer/payment/${paymentid}`)
            .then(async (response) => {
                setAmount(response.data.data.PaymAmount);
                setOrderNo(response.data.data.PaymentId);
                setLoading(false);
            },(error) => {
              setLoading(false);
              console.log(error+"errrr");
            });
    };
    fetchPaymentData();
  }, [paymentid, baseurl]);

  if (error) {
    return (
      <div style={{ color: 'red', fontSize: 16 }}>
        <h1>{error === 'Unauthorized' ? 'You are not authorized to access this page.' : 'Payment expired.'}</h1>
        
      </div>
    );
  }
  //----
  const redirectToHomePage = () => {
    const isConfirmed = window.confirm('Are you sure you want to cancel payment?');
    if (isConfirmed) {
      navigate('/paiements');
    }
  };

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'fr' : 'en');
  };

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setAlert();
    setLoading(true);

    try {
      await  api.post(`saba-validate`, {account_number , paymentid})
      .then(async (response) => {
        setAlert({ type: 'success', message: response.data.message || 'An error occurred' });
        window.location.href = response.data.data.url;
      },(error) => {
        setLoading(false);
        setAlert({ type: 'danger', message: error.response.data.message?? 'An error occurred during the API call' });
      });
    } catch (error) {
      setAlert({ type: 'danger', message: 'An error occurred during the API call' });
    } finally {
      setLoading(false);
    }
  };

  
  return (
    <div className="limiter" style={{zIndex:'999'}}>
      <div className="container-login100">
        <div className="wrap-login100" >
          <div
            style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              backgroundColor: '#dae1f3',
              color: 'white',
              border: 'none',
              padding: '15px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              transition: 'background-color 0.3s ease',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button onClick={() => changeLanguage('fr')} className={language === 'en' ? 'inactive-lang' : 'active-lang'}>
                FR
              </button>
              <span style={{ margin: '0 8px' }}>|</span>
              <button onClick={() => changeLanguage('en')} className={language === 'en' ? 'active-lang' : 'inactive-lang'}>
                EN
              </button>
            </div>
          </div>
          <div className="login100-form validate-form">
            <form className="step1" onSubmit={handleSubmit}>
              <span className="login100-form-title">{translations.initializePayment}</span>
              <div className="wrap-input100 validate-input" data-validate="Account number is required" style={{ padding: 5 }}>
                {alert && (
                  <div className={`alert alert-${alert.type}`} style={{ marginBottom: '10px' }} role="alert">
                    {alert.message}
                  </div>
                )}
                <input
                  className="input100"
                  type="text"
                  name="BagdeID"
                  required
                  placeholder={translations.badgeIdLabel}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
                <span className="focus-input100" />
              </div>
              <div className="container-login100-form-btn">
                <button className={`login100-form-btn ${loading ? 'loading-btn' : ''}`} disabled={loading}>
                  {loading ? 'Loading...' : translations.PaybtnText}
                </button>
              </div>
            </form>
            <div className="text-center p-t-136">
              <a className="txt2" href="#" onClick={redirectToHomePage}>
              <i className="fa fa-long-arrow-left m-l-5" aria-hidden="true" />
              {translations.cancelPayment}
              </a>
            </div>
          </div>
            <div className="login100-pic">
              <span className="login100-form-title">
                <div
                  style={{
                    fontFamily: '"Arial", sans-serif',
                    padding: 15
                  }}
                >
                  <img  src="../assets/logosabaa.png" alt="IMG" />
                  <hr style={{margin:'20px 0px '}}></hr>
                  <h6 style={{ color: "#333", fontSize: 18, marginBottom: 10 }}>
                    {translations.orderDetails}
                  </h6>
                  <p style={{ margin: 0, color: "#555", fontSize: 17 }}>
                    {translations.orderNoLabel}: <b>{orderNO}</b>
                  </p>
                  <p style={{ margin: 0, color: "#555", fontSize: 17 }}>
                    {translations.amountLabel}: <b>{amount} DJF</b>
                  </p>
                  <hr style={{margin:'20px 0px '}}></hr>            
                  <img  variant="top" src="../assets/cnss-logo.svg" /> 
                </div>
              </span>
            </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
