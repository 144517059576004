import React, { useState } from "react";
import { Alert, Badge, Button, Col, FloatingLabel, Form, Modal, Row, Tab, Table, Tabs ,Spinner } from "react-bootstrap";
import { BsCheck, BsDownload, BsFillExclamationCircleFill, BsPrinter, BsSearch } from "react-icons/bs";
import Wizard from "../../Components/Wizard";

import './Forms.scss';
import '../css/Declarations.scss';
import '../vs-mobil/Forms.scss';
import Download from "../Download";
// import axios from "axios";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { api } from '../../index';

  
function AddDeclaration({Add,ItemSelected}) {
    const [show, setShow] = useState(false);
    const [validated, setValidated] = useState(false);
    const [validatedAddF, setValidatedAddF] = useState(false);
    const [VendorAccountNumber, setVendorAccountNumber] = useState();
    const [EmployeeExternalNum, setEmployeeExternalNum] = useState();
    const [VendorOrganizationName, setVendorOrganizationName] = useState();
    const [DeclaredSalary, setDeclaredSalary] = useState("");
    const [ContributionType, setContributionType] = useState();
    const [HiringDate, setHiringDate] = useState();
    const [DebaucheryDate, setDebaucheryDate] = useState("");
    const [ContributionTypeId, setContributionTypeId] = useState("");
    const [searchData,setSearchData]=useState([]);
    const [EditSalary,setEditSalary]=useState(false);
    const [SalarySelected,setSalarySelected]=useState();
    const [AssureData,setAssureData]=useState([]);
    const [success,setSuccess]=useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [ListContribution, setListContribution] = useState([]);
    const [Errors, setErrors] = useState("");
    const [isLoading, setIsLoading] = useState(true); // Track loading state
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const [file1, setFile1] = useState(null);

    //----File input 

    const handleFile1Change = (event) => {
        setFile1(event.target.files[0]);
        
    };
    
    // -----
    const handleInputSearch = (e) => {
        setSearchVal(e.target.value);
      }
    let Assures = [];
         AssureData.filter((dec) => {
            Assures.push(dec.VendorAccountNumber,dec.EmployeeExternalNum ? dec.EmployeeExternalNum.toLocaleString().toLowerCase() :"",dec.EmployeeName ? dec.EmployeeName.toLowerCase() :"",dec.EmployeeName ? dec.EmployeeName :"");
          });
    const searchIn= Assures.filter((dec) => {
         return dec.includes(searchVal.toLowerCase());
     });
     let AssureArray = [];
         AssureData.forEach(index => {
              if (searchIn.includes(index["VendorAccountNumber"]) || searchIn.includes(index["EmployeeExternalNum"] ? index["EmployeeExternalNum"] :"" ) || searchIn.includes(index["EmployeeName"] ? index["EmployeeName"].toLowerCase() :"" ) || searchIn.includes(index["EmployeeName"]? index["EmployeeName"].toLowerCase():"")) {
                AssureArray.push(index)
              }
          })
          useEffect(() => {
            setIsLoading(true); // Set loading state to true before fetching data
        
            api.get(`contributioncall/${ItemSelected}/contributioncalllines`)
              .then(async (response) => {
                setAssureData(response.data.data);
                localStorage.setItem('salaries', JSON.stringify(response.data.data));
              })
              .finally(() => setIsLoading(false)); // Set loading state to false after data is fetched
        
            api.get('contributionTypes')
              .then(async (response) => {
                setListContribution(response.data.data);
              })
              // Set loading state to false after data is fetched
          }, []);
        


    const items = JSON.parse(localStorage.getItem('general'));
    const base_url = process.env.REACT_APP_URL;
    const search = (event) => {
        const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
            setValidated(true);
            api.get(`vendor/${VendorAccountNumber}`).then(async (response) => {
                if(response.data.data.length == 0) setErrors("L'assuré n'existe pas");
                setSearchData(response.data.data) 
                setVendorOrganizationName(response.data.data ? response.data.data[0].VendorOrganizationName : " ")
            }, (error) => {
                setErrors(error.response.data.data.error);
            });
    
    }
   
    const add = (event) => {
        const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            }
            setValidatedAddF(true);
            api.post(`contributioncallline/add-edit`, {
                VendorAccountNumber,
                EmployeeExternalNum,
                EmployeeName: VendorOrganizationName,
                DeclaredSalary,
                ContributionType,
                ContributionTypeId,
                ContributionCallId: items["ContributionCallId"],
                HiringDate,
                file1
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(async (response) => {
                
        
                localStorage.setItem('general',JSON.stringify(response.data.data))
                if(response.data.data){
                    setShow(false)
                    setDeclaredSalary("")
                    api.get(`contributioncall/${ItemSelected}/contributioncalllines`).then(async (response) => {
                        setAssureData(response.data.data);
                        setSearchData([])
                        setErrors('')
                        setValidatedAddF(false);
                        setValidated(false);
                    });
                }
              
            }, (error) => {
                // setErrors(error.response.data.data.error);
            });
    
    }
    const hideSearch = (event) => {
        setShow(false)
        setErrors('')
        setDeclaredSalary("")
        setValidatedAddF(false);
        setValidated(false);
        setSearchData([])
    }

    const SelectedItem = (event) =>{
          setEditSalary(true)
          AssureData.forEach(element => {
            if(element["_id"] == event.currentTarget.id){
                setSalarySelected(element)
                console.log(element)
                setDeclaredSalary(element.DeclaredSalary);
                setContributionTypeId(element.ContributionTypeId);
                setDebaucheryDate(element.DebaucheryDate)
            }
        });
    }
    const SalaryEdit = () =>{
            api.post(`contributioncallline/add-edit`, {
                VendorAccountNumber:SalarySelected.VendorAccountNumber,
                id:SalarySelected._id,
                EmployeeExternalNum:SalarySelected.EmployeeExternalNum ?? "",
                EmployeeName:SalarySelected.VendorOrganizationName,
                DeclaredSalary,
                ContributionType,
                ContributionTypeId,
                ContributionCallId : SalarySelected.ContributionCallId,
                DebaucheryDate,
                file1
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }}).then(async (response) => {
                
                localStorage.setItem('general',JSON.stringify(response.data.data))
                if(response.data.success){
                    setEditSalary(false)
                    api.get(`contributioncall/${ItemSelected}/contributioncalllines`).then(async (response) => {
                        setAssureData(response.data.data);
                       
                    });
                }
              
            }, (error) => {
                setErrors(error.response.data.data.error);
            });
    }
    const submitGeneral = () => {
        setIsSubmitting(true);
        api.get(`contributioncall/${items["ContributionCallId"]}/submit`).then(
            async (response) => {
                setIsSubmitting(false);
                if (response.data.success) {
                    setSuccess(true);
                } else {
                    setSuccess(false);
                    // Vérifier si la réponse contient un message d'erreur
                    if (response.data.data.error) {
                        setError(response.data.data.error);
                    }
                }
            },
            (error) => {
                setIsSubmitting(false);
                setSuccess(false);
                // En cas d'erreur de requête, vérifier si un message d'erreur est retourné
                if (error.response && error.response.data && error.response.data.data && error.response.data.data.error) {
                    setError(error.response.data.data.error);
                } else {
                    setError("Une erreur s'est produite lors de l'envoi du formulaire.");
                }
            }
        );
    };
    

    const columns = [
        {
            name: 'Matricule',
            selector: row => row.matricule,
            sortable: true,
        },
        {
            name: 'Matricule interne',
            selector: row => row.matricule_intern,
            sortable: true,
        },
        {
            name: 'Nom',
            selector: row => row.Nom,
            sortable: true,
        },
        {
            name: 'Type de cotisation',
            selector: row => row.type_cotis,
            sortable: true,
        },
        {
            name: 'Salaire',
            selector: row => row.salaire,
            sortable: true,
        },
        {
            name:"Date d'embauche",
            selector: row => row.date_emb,
            sortable: true,
        },
        {
            name: 'Date de debauche',
            selector: row => row.date_deb,
            sortable: true,
            
        },
        {	
            name:  <img variant="top" className={!Add ? "hide":""} src="./assets/dark-edit.svg" style={{paddingLeft:5}} />,
            cell: (row) => <div className={!Add ? "hide":"btns-bar"}> 
                                <Button variant="outline-success"  onClick={SelectedItem} className={!Add ? "hide":"edit br-15y"} id={row.id_}>
                                    <span className="Icon"></span>
                                </Button>
                            </div>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];
    let data = [];
    AssureArray.map((dec) => {
          data.push({
            id: dec['_id'],
            matricule : dec['VendorAccountNumber'],
            matricule_intern: dec['EmployeeExternalNum'],
            Nom: dec['EmployeeName'],
            type_cotis: dec['ContributionTypeId'],
            salaire: dec['DeclaredSalary'].toLocaleString(),
            date_emb: dec['HiringDate'] === "2154-12-31" ? "-" :
            dec['HiringDate'] === "1900-01-01" ? "-"  : dec['HiringDate'],
            date_deb: dec['DebaucheryDate'] === "2154-12-31" ? "-" :
            dec['DebaucheryDate'] === "1900-01-01" ? "-"  : dec['DebaucheryDate'],
            id_:dec["_id"]
          
          });
        });

        /** import csv */

        const [file, setFile] = useState();
        const [message, setMessage] = useState("");

        const clickInput = () => {
            document.getElementById("file_pd").click() 
        };

        const handleFileChange = (e) => {
          if (e.target.files) {
            setFile(e.target.files[0]);
          }
        };

      
          const handleUploadClick = async () => {
            try {
              const formData = new FormData();
              formData.append("file", file);
              const response = await api.post(`contributioncalllines/import/${items["ContributionCallId"]}`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
             if(response.data){
                setMessage("Le fichier est envoyer avec succès");
               
                setTimeout(() => {
                    window.location.href="/declarations"
                  }, "1000")
             }
              
            } catch (error) {
              setMessage(error.response.data.data.error ?? "une erreur est survenue lors de l'envoi du fichier");
            }
          };
   

  return (
    <>
  
               <Wizard valideted="valideted" pending="pending" Status={items["Status"]}/>
                <Tabs
                    defaultActiveKey="Général"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3 w-100 add-dec"
                    >
                        <Tab eventKey="Général" title="Général">
                            <Form className="left-container">
                            {error ? (
                                <Alert variant="danger" className="mt-1">
                                    {error}
                                </Alert>
                            ) : success ? (
                                <Alert variant="success" className="mt-1">
                                    Le formulaire a été envoyé avec succès.
                                </Alert>
                            ) : null}
                             <Row className="form-row">
                                   <h5 className="mb-3 c-primary">Informations</h5>
                                    <Col sm={12} md={6}>
                                        <Form.Group >
                                            <FloatingLabel  label="Appel de cotisation"  className="uppercase mb-3">
                                                <Form.Control  type="text" value={items["ContributionCallId"]} disabled className="bg-light" />
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Form.Group >
                                            <FloatingLabel  label="Période" className="uppercase mb-3" >
                                                <Form.Control type="text" value={items["SSMCalendarPeriod_Name"]} disabled className="bg-light" />
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Form.Group  >
                                            <FloatingLabel  label="Régime" className="uppercase mb-3">
                                                <Form.Control type="text" value={items["SSMRegime_RegimeId"]}  disabled  className="bg-light" />
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <Form.Group  >
                                            <FloatingLabel  label="Date D échéance" className="uppercase mb-3">
                                                <Form.Control  type="text" value={items["DueDate"]}  disabled className="bg-light" />
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6} className="m-0">
                                        <Form.Group >
                                            <FloatingLabel  label="Statut" className="uppercase mb-3">
                                                <Form.Control 
                                                 type="text" 
                                                 value={
                                                    items["Status"] === "Created" ? "Crée" :
                                                    items["Status"] === "Canceled" ? "Annulé" :
                                                    items["Status"] === "Close" ? "Cloturé" :
                                                    items["Status"] === "Rejected" ? "Rejetée" :
                                                    items["Status"] === "Declared" ? "Déclaré" :
                                                    items["Status"] === "WaitingPortalApproval" ? "En attente d'approbation" :
                                                    items["Status"] === "InDelay" ? "En retard" :"-"
                                                    } disabled  className="bg-light" /> 
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6} className="m-0">
                                        <Form.Group >
                                            <FloatingLabel  label="Statut de paiement" className="uppercase mb-3">
                                                <Form.Control
                                                     type="text"
                                                     value={
                                                        items["PaymentStatus"] === "Created" ? "Crée" :
                                                        items["PaymentStatus"] === "Canceled" ? "Annulé" :
                                                        items["PaymentStatus"] === "Close" ? "Cloturé" :
                                                        items["PaymentStatus"] === "Declared" ? "Déclaré" :
                                                        items["PaymentStatus"] === "InDelay" ? "En retard" :"-"
                                                        } disabled className="bg-light" />
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6} className="m-0">
                                        <Form.Group >
                                            <FloatingLabel  label="Date de paiement" className="uppercase mb-3">
                                                <Form.Control  type="text" value={items["PaymDate"] !== "1900-01-01" ? items["PaymDate"] :"-" }  disabled className="bg-light" /> 
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} md={6} className="m-0">
                                        <Form.Group >
                                            <FloatingLabel  label="Date de Déclaration" className="uppercase mb-3">
                                                <Form.Control  type="text"  value={items["DeclarationDate"] !== "1900-01-01" ? items["DeclarationDate"] :"-" }   disabled className="bg-light"  />
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                    <h5 className="mb-3 c-primary mt-2">Calcul de cotisation</h5>
                                    <Row>
                                        <Col sm={12} md={6} className="m-0" style={{paddingLeft:0}}>
                                            <Form.Group >
                                                <FloatingLabel  label="Cotisations" className="uppercase mb-3">
                                                    <Form.Control  type="text" value={items["ContributionAmount"] > 0 ? items["ContributionAmount"].toLocaleString() : items["ContributionAmount"]}  disabled className="bg-light"  />
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6} className="m-0">
                                        <Form.Group >
                                            <FloatingLabel  label="Nombre de salariés" className="uppercase mb-3">
                                                <Form.Control  type="text"  value={items["DeclaredEmployees"]}  disabled className="bg-light"  />
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6} className="m-0" style={{paddingLeft:0}}>
                                            <Form.Group >
                                                <FloatingLabel  label="Pénalités" className="uppercase mb-3">
                                                    <Form.Control  type="text" value={items["Interest"] > 0 ? items["Interest"].toLocaleString():items["Interest"]} disabled className="bg-light"  />
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={6} className="m-0">
                                            <Form.Group >
                                                <FloatingLabel  label="Masse salariale" className="uppercase mb-3">
                                                    <Form.Control  type="text" value={items["DeclaredPayroll"].toLocaleString()}  disabled className="bg-light"  />
                                                </FloatingLabel> 
                                            </Form.Group>
                                    </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6} className="m-0" style={{paddingLeft:0}}>
                                            <Form.Group >
                                                <FloatingLabel  label="Astreintes" className="uppercase mb-3">
                                                    <Form.Control  type="text"  value={items["PenaltyAmount"].toLocaleString()}  disabled className="bg-light"  />
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Col sm={12} md={6} className="m-0">
                                        <Form.Group >
                                            <FloatingLabel  label="Total" className="uppercase mb-3">
                                                <Form.Control  type="text" value={items["Total"].toLocaleString()}  disabled className="bg-light"  />
                                            </FloatingLabel> 
                                        </Form.Group>
                                    </Col>
                                    <Row>
                                        <Col sm={12} md={6} className="m-0" style={{paddingLeft:0}}>
                                            <Form.Group >
                                                <FloatingLabel  label="Motif du rejet" className="uppercase mb-3">
                                                    <Form.Control  as="textarea"  value={items["Notes"]?.toLocaleString()}  disabled className="bg-light  textArea-height"  />
                                                </FloatingLabel> 
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <div className="gap mb-4">
                                    {Add ? (
                                        <Button
                                        variant="success"
                                        className="succes mt-4 uppercase xs br-30 align-right mb-4"
                                        onClick={submitGeneral}
                                        disabled={isSubmitting} // Disable the button during submission
                                      >
                                        {isSubmitting ? (
                                          <>
                                            <Spinner animation="border" size="sm" role="status" style={{ marginRight: "5px" }} />
                                            <span>Loading...</span>
                                          </>
                                        ) : (
                                          <>
                                            <img variant="top" src="./assets/verify-white.svg" style={{ paddingRight: 5 }} /> Valider
                                          </>
                                        )}
                                      </Button>

                                      
                                    ) : null}
                                        <Button 
                                         variant="light"
                                         className=" mt-4 uppercase xs align-right download br-30" 
                                         href={`${base_url}/export-contribution/${items["ContributionCallId"]}`}
                                        >Imprimer</Button>
                                        
                                    </div>
                             </Row>
                            </Form>
                        </Tab>
                        <Tab eventKey="Assuré" title="Assuré">
                            {message ? 
                                    <Alert  variant="success" className="mt-1 mb-2">
                                        {message}
                                    </Alert>
                            :" "}
                            <Form className="bar-top flex">
                                <div className="m-0 first">
                                        <Form.Control
                                        type="search"
                                        placeholder="Rechercher"
                                        className="me-2 w-50 br-15  m-0 w-100 mb-2"
                                        aria-label="Rechercher"
                                        icon={<BsSearch/>}
                                        onChange={handleInputSearch}
                                        value={searchVal}
                                        />
                                    </div>
                                    
                                        <div className="btns-bar">
                                            {Add? <Button variant="outline-success" className="add br-15" onClick={() => setShow(true)} > <span className="Icon"></span> Ajouter</Button>:""}
                                            <Button className="br-30 download" href={`${base_url}/api/exportCSV/${items["ContributionCallId"]}`}> 
                                                 <BsDownload className="icon-size"  />  Télécharger votre déclaration
                                            </Button>
                                            {Add?
                                            <div className="deverser-btn">
                                                <input  id="file_pd" type="file" onChange={handleFileChange} hidden />

                                                
                                                <Button className="first-btn" onClick={clickInput}> <BsDownload className="icon-size"  /> Déverser</Button>
                                                <div className="file-name">{file && `${file.name}`}</div>
                                                {file ? <Button className="second-btn" onClick={handleUploadClick}>Terminer</Button> :""}
                                                
                                            </div>:""}
                                        </div>
                                    
                            </Form>
                            <Row  className="mt-2  scrol-x-t">
                            {isLoading ? ( // Render loading indicator while data is being fetched
        <div class="loader">
        <div class="loader-wheel"></div>
        <div class="loader-text"></div>
      </div>
      ) : (
        <DataTable
          columns={columns}
          data={data}
          pagination
          highlightOnHover
          fixedHeader
          fixedHeaderScrollHeight="500px"
        // onRowClicked={selected}
        />
      )}

                                               <Modal show={EditSalary} onHide={() => setEditSalary(false)} className="modal-style">
                                                <Modal.Header closeButton className="m-0">
                                                        <Modal.Title className="m-0">Modifications  </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body className="m-0">
                                                        <Row className="form-row">
                                                            <Form  className="mt-4">
                                                                <Col sm={12}>
                                                                    <Form.Group >
                                                                        <FloatingLabel  label="Salaire"  className="uppercase mb-3">
                                                                            <Form.Control  type="text" defaultValue={SalarySelected ? SalarySelected.DeclaredSalary :""} 
                                                                                           onChange={(e) => setDeclaredSalary(e.target.value)}   required  />
                                                                            <Form.Control.Feedback type="invalid">
                                                                            ce champ est obligatoire
                                                                            </Form.Control.Feedback>
                                                                        </FloatingLabel> 
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <Form.Group >
                                                                        <FloatingLabel  label="Date de débauche"  className="uppercase mb-3">
                                                                            <Form.Control  type="date" defaultValue={SalarySelected ? SalarySelected.DebaucheryDate :""}  
                                                                                           onChange={(e) => setDebaucheryDate(e.target.value)}   />
                                                                            <Form.Control.Feedback type="invalid">
                                                                                 ce champ est obligatoire
                                                                            </Form.Control.Feedback>
                                                                        </FloatingLabel> 
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col sm={12}>
                                                                    <Form.Group className="mb-3">
                                                                        <FloatingLabel  label="Type de cotisation" className="uppercase mb-3">
                                                                            <Form.Select onChange={(e) => setContributionTypeId(e.target.value)} defaultValue={SalarySelected ? SalarySelected.ContributionTypeId :""} >
                                                                                <option></option>
                                                                                    {ListContribution.map((index)=> (
                                                                                        <option value={index.ContributionTypeId}>{index.Description}</option>
                                                                                    ))}
                                                                            </Form.Select>
                                                                        </FloatingLabel> 
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col sm={12}  className="m-0">
                                                                                              
                                                        <div className="my-dropzone">

                                                        <div class="custom-file">
                                                        <div> <span class="text-1"> Télécharger</span><span class="text-2"> une pièce jointe </span>
                                                        
                                                        <input type="file" name="file1" onChange={handleFile1Change}  />
                                                        {Errors ? Errors.file1 ? 
                                                            <div className="required">{Errors.file1[0]}</div>
                                                        :"":""}
                                                         {SalarySelected ? SalarySelected.AttachementURL ? (
                                                        <div className="old-file-containerx">
                                                                
   
                                                                <a href={SalarySelected.AttachementURL} download>
              {SalarySelected.AttachementURL.split('/').pop()}
            </a>
                                                                </div>  
                                                        ) : null :null}
                                                        </div>

                                                        </div>
                                                        </div>
                                                       
                                                </Col>
                                                                <div className="gap mb-4">
                                                                    <Button variant="success" onClick={SalaryEdit}   className="succes mt-4 uppercase xs align-right br-30">
                                                                            <img variant="top" src="./assets/edit.svg" style={{paddingRight:5}} />  Modifier 
                                                                    </Button> 
                                                                </div>
                                                            </Form>
                                                        </Row>
                                                </Modal.Body>
                                        </Modal>

                                     

                            </Row>
                            <Modal show={show}  onHide={hideSearch} className="modal-style">
                                <Modal.Header closeButton className="m-0">
                                    <Modal.Title className="m-0">Ajouter </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="m-0">
                                    <Row className="form-row">
                                        <Form noValidate validated={validated}  > 
                                            <Alert  variant="danger" className={Errors ? "" : "hide"}>
                                                {Errors}
                                            </Alert>
                                                <Col sm={12} >
                                                    <Form.Group >
                                                        <FloatingLabel  label="Matricule"  className="uppercase mb-3">
                                                            <Form.Control  type="text" onChange={(e) => setVendorAccountNumber(e.target.value)}  />
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                    <Button variant="success"   onClick={search} className="succes mb-2 uppercase xs align-right br-30">
                                                          <BsSearch  className="icon-size" />  Rechercher 
                                                    </Button> 
                                        </Form>
                                        <Form noValidate validated={validatedAddF} className={searchData.length > 0 ? "" :"hide"}> 
                                                <Col sm={12} >
                                                    <Form.Group >
                                                        <FloatingLabel  label="Matricule interne" className="uppercase mb-3" >
                                                            <Form.Control type="text" disabled value={EmployeeExternalNum} onChange={(e) => setEmployeeExternalNum(e.target.value)}   />
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} >
                                                    <Form.Group  >
                                                        <FloatingLabel  label="Nom" className="uppercase mb-3">
                                                            <Form.Control type="text" disabled value={VendorOrganizationName} onChange={(e) => setVendorOrganizationName(e.target.value)}  />
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12} >
                                                    <Form.Group  >
                                                        <FloatingLabel  label="Salaire" className="uppercase mb-3">
                                                            <Form.Control  type="text" onChange={(e) => setDeclaredSalary(e.target.value)} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                ce champ est obligatoire
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12}>
                                                    <Form.Group className="mb-3">
                                                        <FloatingLabel  label="Type de cotisation" className="uppercase mb-3">
                                                            <Form.Select onChange={(e) => setContributionTypeId(e.target.value)} required>
                                                                   <option></option>
                                                                    {ListContribution.map((index)=> (
                                                                        <option value={index.ContributionTypeId}>{index.Description}</option>
                                                                    ))}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                ce champ est obligatoire
                                                            </Form.Control.Feedback>
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12}  className="m-0">
                                                    <Form.Group >
                                                        <FloatingLabel  label="Date d'embauche" className="uppercase mb-3">
                                                            <Form.Control  type="date"  onChange={(e) => setHiringDate(e.target.value)}   />
                                                        </FloatingLabel> 
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12}  className="m-0">
                                        
                                                        <div className="my-dropzone">

                                                        <div class="custom-file">
                                                        <div> <span class="text-1"> Télécharger </span><span class="text-2"> une pièce jointe </span>
                                                        
                                                        
                                                        <input type="file" name="file1" onChange={handleFile1Change}  />
                                                        {Errors ? Errors.file1 ? 
                                                            <div className="required">{Errors.file1[0]}</div>
                                                        :"":""}
                                                        </div>

                                                        </div>
                                                        </div>
                                                        
                                                </Col>
                                                <div className="gap mb-4">
                                                    <Button variant="success"  onClick={add} className="succes mt-4 uppercase xs align-right br-30">
                                                            <img variant="top" src="./assets/verify-white.svg" style={{paddingRight:5}} />  Envoyer 
                                                    </Button> 
                                                </div>
                                        </Form>
                                    </Row>
                                </Modal.Body>
                            </Modal> 
                        </Tab>

                </Tabs>
            
             
                     
    </>
  );
}
export default AddDeclaration;